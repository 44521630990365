<template>
    <div>
        <CRow>
            <CCol>
                <p> <strong> LOG: </strong> {{ params.log }} </p>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    export default{
        name: "AlertLogDetail",
        props:{
            params: Object,
        },
       
        watch:{
            params: function(val){
                this.data = {...val};
            },
        },
    }
</script>
<style scoped>
     .table-responsive{
        height: auto;
    }
</style>